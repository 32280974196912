<template>
    <div :key="route.path">
        <LayoutMetaData
            :title="title"
            :meta="meta"
        />
        <LayoutContentRouter
            :content="pageStore.getPageData"
        />
    </div>
</template>
<script setup>
import { useHead, useRoute } from '#imports';
import { usePageStore } from '~dsc/stores/cms/page';
import { storeToRefs } from 'pinia';
const pageStore = usePageStore();
const route = useRoute();
const { pageTitle: title, meta } = storeToRefs(usePageStore());

useHead({
    title,
});
</script>
